<template>
  <div class="col-12">
    <div class="point-input">
      <!-- <label>使用積點：</label> -->
      <label class="mb-0 mr-3 mt-1">目前積點：<span style="font-size:22px">{{profile.points}}</span></label>
      <number-input
        style="max-width: 160px;"
        v-model="num"
        :max="limitPoint"
        placeholder="請輸入本次使用點數"
      ></number-input>

        <!-- dir="rtl" -->
    </div>
    <div>
      <small class="remark text-muted">
        <!-- 目前您有{{ profile.points }}點(1點=1元); -->
        (1點=1元)
      </small>
      <small class="remark text-danger">
        若訂單取消、退貨退款，點數將不歸還
      </small>
    </div>
  </div>
</template>

<script>
import { computed, watch,inject } from "vue";
import { useStore } from "vuex";
import { formatMoney } from "@/utils/tools";
import NumberInput from "@/components/NumberInput.vue";
import { getCouponDiscountOrExtra } from "@/components/cart-coupon/utils";
import storage from "../utils/storage";
storage.config = { type: "session" };
export default {
  components: {
    NumberInput,
  },
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    couponValue: {
      type: Object,
      default: () => ({}),
    },
    feeAmount: {
      type:Number,
      default: 0
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    // const num = ref(0);
    const store = useStore();
    store.dispatch("member/getHompageProfileAction");

    const num = computed({
      get() {
        return props.modelValue;
        // return 0
      },
      set(newValue) {
        emit("update:modelValue", newValue);
      },
    });

    const profile = computed(() => store.state.member.homepage);

    //數量
    // const cartQuantity = computed(() => store.getters["carts/cartQuantity"]);
    //總金額
    const cartTotal = computed(() => store.getters["carts/cartTotal"]);
   

    // const total = computed(() => {
    //   const price = Number(cartTotal.value);
    //   return price - num.value;
    // });
    // 服務費也要加入積點折抵
    const limitPoint = computed(() => {
      const points = Number(profile.value.points);
      const price = Number(cartTotal.value);
      const lastPrice =
        price + getCouponDiscountOrExtra(props.couponValue, price) + props.feeAmount;
      return Math.min(points, lastPrice);
    });

    watch(
      () => props.couponValue,
      () => {
        emit("update:modelValue", 0);
      }
    );

    return {
      // cartQuantity,
      cartTotal,
      formatMoney,
      profile,
      num,
      limitPoint,
      // total,
    };
  },
};
</script>

<style lang="scss" scoped>
.remark {
  display: block;
}

.point-input::v-deep .el-input__inner {
  background-color: #e9ecef;
  border: none;
  border-bottom: 2px solid #dee2e6;
}
</style>
