<template>
  <div>
    <div class="check-step bg-white d-flex shadow-sm position-sticky" style="top:0; z-index: 99;">
      <div class="item">
        <span>01.</span>
        <span>{{ $t("CART.STEP1") }}</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item">
        <span>02.</span>
        <span>{{ $t("CART.STEP2") }}</span>
        <span class="material-icons-outlined">navigate_next</span>
      </div>
      <div class="item active">
        <span>03.</span>
        <span>{{ $t("CART.STEP3") }}</span>
      </div>
    </div>
    <div class="check-way" v-if="gotoPayment != ''">
      進行付款作業中.....
      <span v-html="gotoPayment"></span>
    </div>
    <div class="check-way ckeckout-pay" v-else>
      <br />
      <div class="cart-detail">
        <div class="menu-list">
          <div class="form-group container">
            <label>請選擇付款方式：</label>
            <div class="row row-cols-2">
              <div class="col" v-if="shop_pay.store.status_diner === 1">
                <label
                  for="options1"
                  class="btn btn-block"
                  style="border: 1px solid #dee2e6"
                  @click="handlePayType('store')"
                >
                  <input
                    id="options1"
                    type="radio"
                    name="options"
                    value="store"
                    :checked="state.usePayType == 'store'"
                  />
                  現場付款
                </label>
              </div>
              <!-- <div class="col" v-if="shop_pay.bank.status === 1">
                <label
                  for="options0"
                  class="btn btn-block"
                  style="border: 1px solid #dee2e6"
                  @click="handlePayType('bank')"
                >
                  <input
                    id="options0"
                    type="radio"
                    name="options"
                    value="bank"
                    :checked="state.usePayType == 'bank'"
                  />
                  轉帳匯款
                </label>
              </div>-->
              <!-- Line Pay -->
              <div class="col" v-if="shop_pay.linepay.status_diner === 1">
                <label
                  for="options3"
                  class="btn btn-block"
                  style="border: 1px solid #dee2e6"
                  @click="handlePayType('linepay')"
                >
                  <input
                    id="options3"
                    type="radio"
                    name="options"
                    value="linepay"
                    :checked="state.usePayType == 'linepay'"
                  />
                  <img width="60" src="@/assets/images/linepay-logo.png" alt />
                </label>
              </div>
            </div>
            <div
              class="row row-cols-2"
              v-show="state.usePayType == 'bank' && state.paySetting"
              style="
                border-top: 2px dotted #000;
                border-bottom: 2px dotted #000;
                margin: 1rem 0;
              "
            >
              <div class="col-12">轉帳匯款資訊如下：</div>
              <div class="col-2">銀行</div>
              <div class="col-10">{{ state.paySetting.name }}</div>
              <div class="col-2">分行</div>
              <div class="col-10">{{ state.paySetting.branch }}</div>
              <div class="col-2">帳戶</div>
              <div class="col-10">{{ state.paySetting.account_name }}</div>
              <div class="col-2">帳號</div>
              <div class="col-10">{{ state.paySetting.account }}</div>
            </div>
            <div class="row" v-show="state.payDescription">
              <p class="col-12 mb-0 small text-muted">店家備註:</p>
              <div class="col-12 pay_description">{{ state.payDescription }}</div>
            </div>
            <div>
              <br />
            </div>
            <label v-show="shop_is_invoice == 'true'">
              發票類型：
              <span style="color: red">(必填)</span>
            </label>
            <div class="row" v-show="shop_is_invoice == 'true'">
              <div class="col">
                <el-select v-model="state.useBuyerInvoiceType" placeholder="請選擇發票類型">
                  <el-option
                    v-for="item in invoiceOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div class="col" v-show="[2, 3, 4].includes(state.useBuyerInvoiceType)">
                <input
                  v-model="state.useBuyerInvoiceCarrierId"
                  class="form-control"
                  v-show="state.useBuyerInvoiceType === 3"
                  placeholder="請輸入手機條碼載具"
                />
                <input
                  v-model="state.useBuyerInvoiceNumber"
                  type="tel"
                  class="form-control"
                  v-show="state.useBuyerInvoiceType === 2"
                  placeholder="請輸入統一編號"
                />
                <input
                  v-model="state.useBuyerInvoiceNpoBan"
                  type="tel"
                  class="form-control"
                  v-show="state.useBuyerInvoiceType === 4"
                  placeholder="請輸入愛心碼"
                />
              </div>
            </div>
          </div>
          <h3 class="detail-title text-center">商品明細</h3>
          <template v-if="cartList.length > 0">
            <div class="item" v-for="cart in cartList" :key="cart.id">
              <div class="row">
                <app-image :image_url="cart.product.image_url"></app-image>
                <div class="col-6">
                  <div class="title row no-gutters justify-content-between align-items-center">
                    <h3>{{ cart.product.title }}</h3>
                  </div>
                  <div class="introduction">{{ cart.addition_products }}</div>
                  <div class="price">{{ formatMoney(cart.price) }}</div>
                </div>
                <div class="col-2 text-right">X{{ cart.quantity }}</div>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="form-group container">
        <div class="row text-right font-weight-bold" style="font-size: medium">
          <div class="col-12">
            <span>小計：</span>
            <span class="text-main">{{ cartQuantity }}</span>
            <span>{{ $t("ORDER.TOTAL.GOODS_UNIT") }}</span>
            <span style="margin: 10px; color: darkgray">｜</span>
            <span>{{ $t("ORDER.TOTAL.MONEY") }}：</span>
            <span class="text-main">$ {{ formatMoney(cartTotal) }}</span>
            <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
          </div>
        </div>
      </div>
      <!-- <cart-coupon
        class="container"
        v-model="couponValue"
        :currentPrice="Number(cartTotal)"
        :kind="2"
      />-->
      <div class="form-group container">
        <div class="row text-right font-weight-bold ponit-block" style="font-size: medium">
          <div class="col-12">
            <span>服務費：</span>
            <span class="text-main">$ {{ formatMoney(feeCount) }}</span>
            <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
          </div>
          <point v-model="pointValue" :feeAmount="feeCount" />
          <div class="col-12">
            <!-- <span>{{ state.shippingTypeName }}運費</span> 
            <span class="text-main">${{ state.shippingAmount }}</span> 
            <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>-->
            <!-- <span
              style="color: #d3d3d3; font-width: normal"
              v-if="state.shippingFreeDescription > 0"
            >
              <br />
              (滿{{ state.shippingFreeDescription }}元免運費)
            </span>-->
          </div>
          <!-- <div class="col-12" v-show="state.payAmount > 0">
            <span>金流手續費：</span>
            <span class="text-main">${{ state.payAmount }}</span>
            <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
          </div>-->
          <div class="col-12">
            <span>總計：</span>
            <span class="text-main" style="font-size:1.35rem">
              $
              {{
              formatMoney(
              parseInt(cartTotal, 10) +
              feeCount -
              pointValue +
              getCouponDiscountOrExtra(couponValue, Number(cartTotal))
              )
              }}
            </span>
            <span>{{ $t("ORDER.TOTAL.MONEY_UNIT") }}</span>
          </div>
        </div>
      </div>
    </div>
    <nav class="checkout-menu">
      <div class="row no-gutters justify-content-center w-100 fixed-bottom bg-light">
        <div class="col px-3">
          <a
            href="/checkout"
            class="btn bg-dark btn-block shadow text-white my-2"
            id="optionClose"
          >{{ $t("CHECKOUT.BUTTON.BACK") }}</a>
        </div>
        <div class="col px-3">
          <button
            type="button"
            class="btn btn-block btn-main shadow text-white my-2"
            @click="onCheckout"
            :disabled="isCheckouting"
          >
            {{ $t("CHECKOUT.BUTTON.NEXT") }}
            <i class="fa-solid fa-cog fa-spin" v-if="sendDataLoading"></i>
          </button>
        </div>
      </div>
    </nav>
  </div>
</template>
<style>
.check-way .check-way-list .list-group-item {
  cursor: pointer;
}
.my-2 {
  padding: 8% 0;
}
.form-group:last-child {
  margin-bottom: 6rem;
}
.pay_description {
  word-break: break-all;
  white-space: break-spaces;
}
.el-select {
  width: 100%;
}
</style>
<script>
//Add By Bryant
import md5 from "js-md5";
//import utf8 from 'utf8';
//=====
import CascaderCites from "@/components/CascaderCites";
import { computed, reactive, watch, inject, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from "date-fns-tz";
import { formatMoney } from "@/utils/tools";
import Swal from "sweetalert2";
import storage from "@u/storage";
import AppImage from "@/components/AppImage.vue";
import Point from "@/components/Point.vue";
import Schema from "async-validator";
// import CartCoupon from "@/components/cart-coupon/index.vue";
import { getCouponDiscountOrExtra } from "@/components/cart-coupon/utils";
storage.config = { type: "session" };
import moment from "moment";
export default {
  name: "CheckoutConfirm",
  components: {
    AppImage,
    Point,
    // CartCoupon,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  emits: ["onLoading"],
  setup(props, { emit }) {
    const shop_name = inject("shop_name"); // 取得商店名稱
    const shop_setting = inject("shop_setting"); // 取餐方式
    // console.log(shop_setting);
    const shop_shipping = inject("shop_shipping"); // 取得取貨方式
    const shop_pay = inject("shop_pay"); // 取得付款方式
    // console.log(shop_pay);
    const shop_is_invoice = inject("shop_is_invoice"); // 取得商店是否開發票
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const pointValue = ref(null);
    const couponValue = ref({});
    const gotoPayment = "";

    //無資料就回到產品列表頁面
    store.dispatch("carts/isEmptyCarts");
    const isEmptyCarts = computed(() => {
      return store.getters["carts/isEmptyCarts"];
    });

    //預設資料

    //發票選項
    const invoiceOptions = [
      {
        value: 1,
        label: "電子發票-個人",
      },
      {
        value: 2,
        label: "電子發票-公司",
      },
      {
        value: 3,
        label: "手機條碼載具",
      },
      {
        value: 4,
        label: "愛心碼",
      },
    ];

    const checkoutInfo = storage.getItem("checkout_info"); // 結帳資訊(地址、電話、姓名)
    // console.log(checkoutInfo);
    if (!checkoutInfo) router.push("/checkout");
    const state = reactive({
      usePayType: "store",
      useCheckoutType: null,
      usePickDate: checkoutInfo.pick_date,
      meal_at: checkoutInfo.meal_at,
      people: checkoutInfo.people,
      useComment: checkoutInfo.comment,
      useBuyerAddress: checkoutInfo.buyer_address,
      useBuyerCityId: checkoutInfo.buyer_city_id,
      useBuyerCityareaId: checkoutInfo.buyer_cityarea_id,
      useBuyerName: checkoutInfo.buyer_name,
      useBuyerPhone: checkoutInfo.buyer_phone,
      useReceiverName: checkoutInfo.receiver_name,
      useReceiverPhone: checkoutInfo.receiver_phone,
      useReceiverAddress: checkoutInfo.receiver_address,
      useReceiverCityId: checkoutInfo.receiver_city_id,
      useReceiverCityareaId: checkoutInfo.receiver_cityarea_id,
      useBuyerInvoiceType:
        shop_is_invoice.value == "true" ? checkoutInfo.invoice.type : 0,
      useBuyerInvoiceCarrierId: checkoutInfo.invoice.carrier_id,
      useBuyerInvoiceNumber: checkoutInfo.invoice.vat_number,
      useBuyerInvoiceNpoBan: checkoutInfo.invoice.npo_ban,
      useBuyerTable1Id: checkoutInfo.table1_Id,
      useBuyerTable2Id: checkoutInfo.table2_Id,
      shippingAmount: 0,
      shippingId: 0,
      shippingTypeName: null,
      shippingDescription: null,
      shippingFreeDescription: null,
      payAmount: 0,
      payId: 0,
      paySetting: "",
      payDescription: "",
      useTypeSetting: checkoutInfo.useTypeSetting,
    });
    // console.log(state);
    // 設定取餐方式
    if (
      shop_setting.value.order.status === 1 && // 現場點餐
      checkoutInfo.type === "order"
    ) {
      state.useCheckoutType = "order";
    } else if (
      shop_setting.value.here.status === 1 && // 預約內用
      checkoutInfo.type === "here"
    ) {
      state.useCheckoutType = "here";
    } else if (
      shop_setting.value.togo.status === 1 && // 到店自取
      checkoutInfo.type === "togo"
    ) {
      state.useCheckoutType = "togo";
    } else if (
      shop_setting.value.delivery.status === 1 && // 店家外送
      checkoutInfo.type === "delivery"
    ) {
      state.useCheckoutType = "delivery_d";
    }

    // 設定付款方式
    // state.useCheckoutType !== "orderIn"
    //   ? (state.usePayType = "store")
    //   : (state.usePayType = "cod");
    // state.useCheckoutType !== "orderIn"
    //   ? (state.payDescription = shop_pay.value.store.description)
    //   : (state.payDescription = shop_pay.value.cod.description);
    // 數量
    const cartQuantity = computed(() => store.getters["carts/cartQuantity"]);
    // 總金額
    const cartTotal = computed(() => store.getters["carts/cartTotal"]);
    // 是否為低溫宅配
    const cartShippingIsCold = computed(
      () => store.getters["carts/cartShippingIsCold"]
    );
    // 取得服務費
    const feeSetting = computed(() => store.getters["shop/fee"]);
    // console.log(feeSetting);
    const soldOutProduct = computed(() => store.getters["carts/soldOutProduct"]);
    const errorProduct = computed(() => store.getters["carts/errorProduct"]);

    const __savePayAmount = () => {
      const pay_setting = shop_pay.value;
      if (Object.prototype.hasOwnProperty.call(pay_setting, state.usePayType)) {
        let my_pay_setting = pay_setting[state.usePayType];
        state.payAmount = my_pay_setting.fee;
        state.payId = my_pay_setting.pay_method_id;
        state.payDescription = my_pay_setting.description_diner;
        state.paySetting =
          state.usePayType === "bank" ? my_pay_setting.setting : "";
      }
    };
    const __saveShippingAmount = () => {
      const shipping_setting = shop_shipping.value;
      if (
        Object.prototype.hasOwnProperty.call(
          shipping_setting,
          state.useCheckoutType
        )
      ) {
        let my_shipping_setting = shipping_setting[state.useCheckoutType];
        if (state.useCheckoutType === "delivery") {
          my_shipping_setting =
            cartShippingIsCold.value !== undefined
              ? my_shipping_setting.cold
              : my_shipping_setting.amb;
        }
        state.shippingAmount =
          cartTotal.value > my_shipping_setting.free
            ? 0
            : my_shipping_setting.fee;
        state.shippingDescription = my_shipping_setting.description;
        state.shippingFreeDescription = my_shipping_setting.free;
        state.shippingTypeName = my_shipping_setting.name;
        state.shippingId = my_shipping_setting.shipping_id;
      } else {
        state.shippingAmount = 0;
        state.shippingDescription = null;
        state.shippingFreeDescription = null;
        state.shippingTypeName = null;
        state.shippingAmount = 0;
      }
    };

    //Add By Bryant
    const str2hex = (str) => {
      let hexstr = unescape(encodeURIComponent(str))
        .split("")
        .map(function (v) {
          return v.charCodeAt(0).toString(16).padStart(2, "0");
        })
        .join("");

      return hexstr;
    };

    const do_CloudPrint = (printData) => {
      //先取得商品清單
      //console.log('cartList.value: ', cartList.value);
      let str_for_print_hex = "";

      if (cartList.value.length > 0) {
        var str_for_print = "";

        cartList.value.forEach((ListItem) => {
          //console.log('ListItem: ', ListItem);
          //console.log('ListItem.product.title: ', ListItem.product.title);

          str_for_print += ListItem.product.title + "\n";

          if (ListItem.addition_products) {
            str_for_print += "\t" + ListItem.addition_products + "\n";
          } else {
            //console.log('No ListItem.product.addition_products, Why!?');
          }

          str_for_print +=
            "\t" +
            "$" +
            ListItem.product.price +
            " X " +
            ListItem.quantity +
            "\n";
        });

        //return false;

        //let print_head = unescape(encodeURIComponent('商品明細' + "\n" + '------------------------' + "\n")).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        let print_head = str2hex("商品明細" + "\n");
        print_head += str2hex("------------------------" + "\n");
        print_head = "1B21301B6131" + print_head + "0A";
        print_head = print_head + "1B6130" + "0A";

        //let username = unescape(encodeURIComponent('取貨姓名: '  + state.useBuyerName + "\n")).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //let userphone = unescape(encodeURIComponent('取貨電話: '  + state.useBuyerPhone + "\n")).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //let lll = unescape(encodeURIComponent('------------------------' + "\n")).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //let Desc = username + userphone + lll;

        let d = new Date();

        let currentDTStr =
          d.getFullYear() +
          "-" +
          (d.getMonth() + 1).toString().padStart(2, 0) +
          "-" +
          d.getDate().toString().padStart(2, 0) +
          " " +
          d.getHours().toString().padStart(2, 0) +
          ":" +
          d.getMinutes().toString().padStart(2, 0) +
          ":" +
          d.getSeconds().toString().padStart(2, 0);

        let orderInfo = "";

        //console.log('currentDTStr: ', currentDTStr);

        //return false;

        if (state.useCheckoutType == "instore") {
          orderInfo += str2hex("自取" + "\n");
        } else {
          orderInfo += str2hex("宅配" + "\n");
        }

        //orderInfo += str2hex('訂單時間: ' + currentDTStr + "\n");
        orderInfo += str2hex("訂單時間: ");
        orderInfo += "1B2110" + str2hex(currentDTStr) + "1B2130" + "0A";
        orderInfo += str2hex("取貨姓名: " + state.useBuyerName + "\n");
        orderInfo += str2hex("取貨電話: " + state.useBuyerPhone + "\n");
        orderInfo += str2hex("\n" + "------------------------" + "\n");

        let print_tail = "\n" + "------------------------" + "\n";
        print_tail += "小計: " + cartQuantity.value + "項" + "\n";
        print_tail += "金額: " + parseInt(cartTotal.value) + "元" + "\n";

        //str_for_print_hex = unescape(encodeURIComponent(str_for_print)).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        //str_for_print_hex = unescape(encodeURIComponent(str_for_print + print_tail)).split('').map(function(v){return v.charCodeAt(0).toString(16).padStart(2, '0')}).join('');
        str_for_print_hex = str2hex(str_for_print + print_tail);
        //console.log('str_for_print_hex: ', str_for_print_hex);
        str_for_print_hex = "1B2130" + str_for_print_hex; //把字寬高加大
        //console.log('str_for_print_hex: ', str_for_print_hex);
        //str_for_print_hex = print_head + str_for_print_hex;
        //str_for_print_hex = print_head + Desc + str_for_print_hex;
        str_for_print_hex = print_head + orderInfo + str_for_print_hex;

        str_for_print_hex = str_for_print_hex + "0A0A0A0A0A1D5631"; //空5行後裁紙
      }
      var currenTS = Math.floor(Date.now() / 1000);
      //console.log('currenTS: ', currenTS);

      const URL = "https://openapi.sunmi.com/v1/printer/pushContent";
      var queryString = "app_id=09978ba6ff2b43488a53e0bbafd7acd2";
      queryString += "&msn=N404212K00159";
      queryString += "&orderCnt=1";
      //queryString += '&orderData=e6b8ace8a9a6e59586e593810ae696b0e5b8bde5ad900a'; //Just For Test
      queryString += "&orderData=" + str_for_print_hex; //Just For Test
      queryString += "&orderType=1";
      queryString += "&pushId=" + currenTS;
      queryString += "&timestamp=" + currenTS;
      //queryString += '&voice=Line 訂單列印終';
      queryString += "&voice=啟秉皇上,來訂單啦";
      queryString += "&voiceCnt=1";

      var qS2 = queryString + "375f21532d594feab41ed30c16092704";
      //      console.log('qS2: ', qS2);

      var md5qS2 = md5(qS2);
      md5qS2 = md5qS2.toUpperCase();
      //      console.log('md5qS2: ', md5qS2);

      queryString += "&sign=" + md5qS2;

      //Tmp Use
      //return false;

      var xhr = new XMLHttpRequest();
      xhr.open("POST", URL, true);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.send(queryString);
      //=====
    };
    //=====

    //一開始就要取資料
    store.dispatch("carts/getCarts").then(() => {
      __saveShippingAmount();
      __savePayAmount();
      if (store.getters["carts/errorProduct"]) {
        setErrorProduct(false);
        return
      }
      if (cartList.value.length === 0) {
        if (storage.getItem("category_id")) {
          if (window.history.length > 2) {
            if (storage.getItem("category_id") > 0)
              router.push("/home/" + storage.getItem("category_id"));
            else router.go(-1);
          } else {
            // router.push("/");
            router.push("/member");
          }
        }
      }
    }); //取購物車

    //computed: getter
    const isLoading = computed(() => store.getters["carts/isLoading"]);
    const myIsLoading = computed(() => {
      emit("onLoading", isLoading.value);
      return props.isLoading;
    });
    const cartList = computed(() => store.getters["carts/cartList"]);
    const isCheckouting = computed(() => store.getters["carts/isCheckouting"]);
    const checkoutData = computed(() => store.getters["carts/checkoutData"]);
    const profile = computed(() => store.getters["member/profile"]);
    const defaultAddress = computed(
      () => store.getters["member/defaultAddress"]
    );
    const sendDataLoading = ref(false);
    // methods
    // 服務費計算
    const feeCount = computed(() => {
      // console.log(feeSetting.value);
      let fee = 0;
      if (
        state.useCheckoutType === "order" ||
        state.useCheckoutType === "here"
      ) {
        if (feeSetting.value.type === 0) {
          fee = 0;
        } else {
          fee = Math.round((cartTotal.value * feeSetting.value.percent) / 100);

          // // 服務費以原價計算
          // if (feeSetting.value.type === 1) {
          //   fee = Math.round((cartTotal.value * feeSetting.value.percent) / 100);
          // }
          // // 服務費以折扣後的金額計算
          // // getCouponDiscountOrExtra(couponValue, Number(cartTotal))
          // else if (feeSetting.value.type === 2){
          //   fee = Math.round(
          //     ((cartTotal.value - pointValue.value) * feeSetting.value.percent) /
          //       100
          //   );
          // }
          // else if (feeSetting.value.type === 0){
          //   fee = 0
          // }
        }
      } else {
        fee = 0;
      }
      return fee;
    });

    const setErrorProduct = (val) => {
      store.commit("carts/setErrorProduct", val);
    };
    const handlePayType = (key) => {
      // console.log(key)
      state.usePayType = key;
      __savePayAmount();
      __saveShippingAmount();
    };
    //送出訂單
    const onCheckout = () => {
      sendDataLoading.value = true;
      if (validateCheckout() === false) return false;
      // 送出訂單前，先判斷下單時間是否有逾時
      if (state.useCheckoutType == "order") {
        state.usePickDate = moment().format("YYYY-MM-DD");
        state.meal_at = moment().format("HH:mm");
      } else {
        const orderTime = moment(state.usePickDate)
          .add(state.meal_at, "minutes")
          .toDate();
        if (
          moment()
            .add(state.useTypeSetting.pre_order_after_minute, "minutes")
            .isAfter(orderTime)
        ) {
          onAlert("error", "下單時間逾時，請重新選擇時間");
          router.push("/checkout");
          return;
        }
      }

      //Add By Bryant

      //do_CloudPrint();
      //console.log('checkoutData: ', checkoutData);
      //console.log('type: ', state.useCheckoutType);
      //console.log('buyer_name: ', state.useBuyerName);
      //console.log('buyer_phone: ', state.useBuyerPhone);

      //return false; //Tmp Use

      //=====

      store
        .dispatch("carts/checkoutOrder", {
          shipping_id: state.shippingId,
          pay_id: state.payId,
          pay_type: state.usePayType,
          // pick_date: state.usePickDate.split(" ")[0],
          pickDate: state.usePickDate.split(" ")[0],
          meal_at: state.meal_at,
          people: state.people,
          point: pointValue.value,
          member_coupon_id: couponValue.value.id,
          type: state.useCheckoutType,
          comment: state.useComment,
          receiver_city_id: state.useReceiverCityId,
          receiver_cityarea_id: state.useReceiverCityareaId,
          receiver_address: state.useReceiverAddress,
          receiver_name: state.useReceiverName,
          receiver_phone: state.useReceiverPhone,
          buyer_name: state.useBuyerName,
          buyer_phone: state.useBuyerPhone,
          buyer_city_id: state.useBuyerCityId,
          buyer_cityarea_id: state.useBuyerCityareaId,
          buyer_address: state.useBuyerAddress,
          table1_Id: state.useBuyerTable1Id,
          table2_Id: state.useBuyerTable2Id,
          invoice: {
            type: state.useBuyerInvoiceType,
            vat_number: state.useBuyerInvoiceNumber,
            carrier_id: state.useBuyerInvoiceCarrierId,
            npo_ban: state.useBuyerInvoiceNpoBan,
          },
        })
        .then(() => {
          sendDataLoading.value = false;
        })
        .catch(() => {
          onAlert("error");
        });
    };
    const validateCheckout = () => {
      let error_msg = "";
      let schema = {};
      if (state.useCheckoutType === "delivery") {
        schema["useBuyerName"] = {
          required: true,
          type: "string",
          message: "訂購人：請填入資料，不可空白",
        };
        schema["useBuyerPhone"] = {
          required: true,
          type: "string",
          min: 8,
          max: 10,
          pattern: /^[0-9]+$/,
          message: "訂購人電話：格式不符(8-10碼數字）",
        };
        schema["useBuyerCityId"] = {
          required: true,
          type: "number",
          message: "訂購人城市：請選擇城市",
        };
        schema["useBuyerCityareaId"] = {
          required: true,
          type: "number",
          message: "訂購人區域：請選擇區域",
        };
        schema["useBuyerAddress"] = {
          required: true,
          type: "string",
          message: "訂購人地址：請填入資料，不可空白",
        };
        if (state.showReceiver === true) {
          schema["useReceiverName"] = {
            required: true,
            type: "string",
            message: "收件人：請填入資料，不可空白",
          };
          schema["useReceiverPhone"] = {
            required: true,
            type: "string",
            min: 8,
            max: 10,
            pattern: /^[0-9]+$/,
            message: "收件人電話：格式不符(8-10碼數字）",
          };
          schema["useReceiverCityId"] = {
            required: true,
            type: "number",
            message: "收件人城市：請選擇城市",
          };
          schema["useReceiverCityareaId"] = {
            required: true,
            type: "number",
            message: "收件人區域：請選擇區域",
          };
          schema["useReceiverAddress"] = {
            required: true,
            type: "string",
            message: "收件人地址：請填入資料，不可空白",
          };
        }
      } else {
        schema["useBuyerName"] = {
          required: true,
          type: "string",
          message: "取貨人姓名：請填入資料，不可空白",
        };
        schema["useBuyerPhone"] = {
          required: true,
          type: "string",
          min: 8,
          max: 10,
          pattern: /^[0-9]+$/,
          message: "取貨人電話：格式不符(8-10碼數字）",
        };
      }

      schema["useBuyerInvoiceType"] = {
        required: true,
        type: "number",
        message: "發票類型：請選擇類型",
      };
      if (state.useBuyerInvoiceType === 2) {
        schema["useBuyerInvoiceNumber"] = {
          len: 8,
          type: "string",
          pattern: /^[0-9]{8}$/,
          required: true,
          message: "公司統編：格式錯誤(8碼數字）",
        };
      }
      if (state.useBuyerInvoiceType === 3) {
        schema["useBuyerInvoiceCarrierId"] = {
          type: "string",
          pattern: /^\/{1}[0-9A-Z+-.]{7}$/,
          required: true,
          message: "手機載具：格式錯誤(需為 / 開頭）",
        };
      }
      if (state.useBuyerInvoiceType === 4) {
        schema["useBuyerInvoiceNpoBan"] = {
          min: 3,
          max: 7,
          pattern: /^[0-9]+$/,
          type: "string",
          required: true,
          message: "愛心碼：格式錯誤（3-7碼數字）",
        };
      }
      const validator = new Schema(schema);
      validator.validate(state, (errors, fields) => {
        if (errors) {
          errors.forEach((error) => (error_msg += error.message + "<br />"));
        }
      });
      if (error_msg) {
        onAlert("error", error_msg);
        return false;
      } else {
        return true;
      }
    };
    const onAlert = (type, error_msg = null) => {
      let title = type === "success" ? t("ALERT.SUCCESS") : t("ALERT.ERROR");
      error_msg =
        type !== "error"
          ? ""
          : "<br /><span style='color:red'>" + error_msg + "</span>";
      Swal.fire({
        title: title,
        html: "確認結帳失敗" + error_msg,
        icon: type,
        showConfirmButton: true,
        confirmButtonColor: "#fd7e14",
        confirmButtonText: "關閉",
        timer: 5000,
      });
    };

    return {
      // data
      gotoPayment,
      shop_name,
      shop_setting,
      shop_shipping,
      shop_pay,
      shop_is_invoice,
      state,
      soldOutProduct,
      errorProduct,
      isEmptyCarts,
      myIsLoading,
      cartList,
      cartQuantity,
      cartTotal,
      defaultAddress,
      isCheckouting,
      checkoutData,
      invoiceOptions,
      storage,
      formatMoney,
      pointValue,
      couponValue,
      getCouponDiscountOrExtra,
      feeSetting,
      sendDataLoading,
      setErrorProduct,
      // methods
      handlePayType,
      onCheckout,
      format,
      onAlert,
      str2hex,
      do_CloudPrint,
      feeCount,
    };
  },
  watch: {
    soldOutProduct: function (newVal, oldVal) {
      if (newVal.length > 0) {
        Swal.fire({
          title: "商品庫存不足或售完",
          html: newVal.join("<br />"),
          icon: "error",
          showConfirmButton: false,
          timer: 5000,
        });
        this.$router.push("/carts");
      }
    },
    errorProduct: function (newVal, oldVal) {
      // console.log("errorProduct", newVal);
      if (newVal) {
        Swal.fire({
          title: "商品異常",
          icon: "error",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonColor: "#fd7e14",
          confirmButtonText: "確認"
        }).then(() => {
          this.$router.push("/shop/carts");
        });
      }
    },
    checkoutData: function () {
      // console.log(this.checkoutData);
      if (this.checkoutData !== null && !this.isCheckouting) {
        if (this.checkoutData.is_created === true) {
          storage.removeItem("checkout_info");
          // 綠界
          if (Object.prototype.hasOwnProperty.call(this.checkoutData, "goto")) {
            this.gotoPayment = this.checkoutData.goto;
            this.$nextTick(function () {
              document.getElementById("data_set").submit();
            });
          } // linepay
          else if (
            Object.prototype.hasOwnProperty.call(this.checkoutData, "linepay")
          ) {
            const url = this.checkoutData.linepay.info.paymentUrl.web;
            window.location.href = url;
          } else {
            // 送出訂單，畫面跳轉至4.完成 訂單送出頁面
            this.$router.push("/checkout-finish");
          }
        } else {
          let msg = "";
          if (this.checkoutData.error.code === 12005) {
            msg = "購物車無商品，請重新確認。";
            this.$router.push("/home");
          }
          if (this.checkoutData.error.code === 6110)
            msg = "付款方式錯誤，請重新確認。";
          if (this.checkoutData.error.code === 6111) {
            msg = "取貨方式錯誤，請重新確認。";
            this.$router.push("/checkout");
          }
          if (this.checkoutData.error.code === 6112) {
            msg = "產品已售完，請移除該商品。";
          }
          if (this.checkoutData.error.code === 6113) {
            msg =
              "產品庫存不足，請調整商品數。<br>" +
              this.checkoutData.error.message;
            this.$router.push("/carts");
          }
          this.onAlert("error", msg);
        }
      }
    },
    cartList: function (data) {
      // console.log("cartchange", this.checkoutData);
      if (this.cartList.length === 0 && this.checkoutData === null) {
        if (storage.getItem("category_id") > 0)
          this.$router.push("/home/" + storage.getItem("category_id"));
        else this.$router.push("/home");
      }
    },
    isEmptyCarts: function (newVal, oldVal) {
      if (newVal === true) {
        if (this.storage.getItem("category_id") > 0)
          this.$router.push(
            "/home/" + this.storage.getItem("category_id", { type: "session" })
          );
        else this.$router.push("/home");
      }
    },
  },
};
</script>

<style lang="scss" scope>
.ponit-block {
  & > div {
    margin-bottom: 12px;
  }
}
.ckeckout-pay {
  min-height: calc(100vh - 50px - 70px);
  overflow-y: auto;
}
</style>
