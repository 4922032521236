<template>
  <div class="col">
    <el-cascader
      v-model="cascader_value"
      :options="cascader_options"
      :props="cascader_props"
      :placeholder="
        $t('FORM.ENTRY', {
          field: $t('PROFILE.CITY') + '/' + $t('PROFILE.CITYAREA'),
        })
      "
      @change="handleChange"
      clearable
    ></el-cascader>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";

export default {
  props: {
    cityId: {
      type: Number,
      default: 0,
    },
    cityareaId: {
      type: Number,
      default: 0,
    },
    type:{
      type: String,
      default: "",
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const cascader_options = computed(() => store.state.postal.cascader);
    store.dispatch("postal/getCascader"); //取資料
    const cascader_props = { expandTrigger: "hover" };
    let cascader_value = ref([props.cityId, props.cityareaId]);
    const handleChange = (value) => {
      // console.log(value)
      // console.log("地址", value);
      emit("change", value, props.type);
    };

    watch(
      () => ({ ...props }),
      (newVal, oldVal) => {
        cascader_value.value = [newVal.cityId, newVal.cityareaId];
        // console.log(newVal, oldVal);
      }
    );

    return {
      cascader_options,
      cascader_props,
      cascader_value,
      handleChange,
    };
  },
};
</script>
