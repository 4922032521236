// promotion_type

// 0：打折
// 1：折抵
// 2：贈送商品
// 3：升級商品

export function getCouponDiscountOrExtra(currentCoupon, currentPrice) {
  if (Object.keys(currentCoupon).length === 0) return 0;
  switch (currentCoupon.promotion_type) {
    case 0:
      return (
        Math.round(((100 - currentCoupon.discount) * currentPrice) / 100) * -1
      );
    case 1:
      return currentCoupon.discount * -1;
    case 2:
    case 3:
      return currentCoupon?.extra_price || 0;
    default:
      break;
  }
}
