<template>
  <el-input :model-value="modelValue" @input="inputhandle"></el-input>
</template>

<script>
export default {
  // props: ['modelValue', 'negative'],
  props: {
    modelValue: {
      type: [Number, String],
    },
    negative: Boolean,
    max: {
      type: Number,
    },
  },
  // emits: ["update:modelValue"],
  setup(props, { emit }) {
    function isFloor(value) {
      var t = value.charAt(0);
      let newValue;
      newValue = value.replace(/[^\d]/g, "");
      if (t == "-") {
        return "-" + newValue;
      }
      return newValue;
    }

    const inputhandle = (value) => {
      // console.log(isFloor(value))
      if (props.negative) {
        const newValue = isFloor(value);
        emit("update:modelValue", newValue);
        return;
      }
      const newValue = value.replace(/[^\d]/g, "");
      let numberValue = Number(newValue);
      if (props.max !== undefined && numberValue > props.max) {
        numberValue = props.max;
      }
      emit("update:modelValue", newValue ? numberValue : null);
    };

    return {
      inputhandle,
    };
  },
};
</script>

<style scoped lang="scss"></style>
